import React from "react";
import { Link } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const SupportUsPage: React.FC = () => (
    <Layout>
        <SEO title="Support Us" />

        <section className="section">
            <div className="container content">
                <h1 className="title is-1">How You Can Help</h1>
                <p>We're looking for assistance with:</p>
                <ul>
                    <li>
                        mentors to help with technical training and interview
                        preparation
                    </li>
                    <li>
                        meetup space in the San Francisco Bay Area (ideally
                        Berkeley)
                    </li>
                    <li>
                        reaching companies that would like to hire people on the
                        autism spectrum
                    </li>
                    <li>
                        opening our{" "}
                        <Link to="/educational-center/">
                            educational center in Berkeley
                        </Link>
                    </li>
                    <li>
                        sponsorship to fund our meetings (renting space, food,
                        technical training, etc.)
                    </li>
                </ul>
                <p>
                    Please <Link to="/contact/">contact us</Link> for more
                    information on how to help.
                </p>
            </div>
        </section>
    </Layout>
);

export default SupportUsPage;
